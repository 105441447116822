import React from 'react';
import './Inputs.scss';

interface TextValueInputProps {
  name: string;
  title: string;
  textValue?: string;
  setTextValue?: (name: string) => void;
}

export function TextValueInput(props: TextValueInputProps): JSX.Element {
  return (
    <div>
      <label htmlFor="name" className="text-input-label">
        {props.title}
      </label>
      <div className="mt-1">
        <input
          type="text"
          name={props.name}
          className="text-input"
          defaultValue={props.textValue}
          autoFocus
          onChange={(e) => props.setTextValue?.(e.target.value)}
        />
      </div>
    </div>
  );
}
