import React, { useEffect, useState } from 'react';
import { PasswordInput, TextValueInput } from '../../../components';

type AddUserDetailsProps = {
  username: string;
  password: string;
  save: (username: string, password: string) => void;
};

export function AddUserAccount(props: AddUserDetailsProps): JSX.Element {
  const [username, setUsername] = useState(props.username);
  const [password, setPassword] = useState(props.password);

  useEffect(() => {
    props.save(username, password);
  }, [username, password]);

  return (
    <div className="p-4 border border-gray-200 border-t-transparent rounded-b-lg">
      <div className="flex flex-col gap-3">
        <TextValueInput name="username" title="Username" textValue={username} setTextValue={setUsername} />
        <PasswordInput password={password} setPassword={setPassword} />
      </div>
    </div>
  );
}
