import React from 'react';

import { FrontendClaim, FrontendScope } from '../../../contracts';
import { SearchBar } from '../../../components';

import { GroupClaimsRenderer } from './GroupClaimsRenderer';
import { GroupScopesRenderer } from './GroupScopesRenderer';
import { CheckIcon } from '@heroicons/react/24/outline';

type EditGroupScopesProps = {
  claims: FrontendClaim[];
  scopes: FrontendScope[];
  getEnabledScopesByClaim: (claim: FrontendClaim) => FrontendScope[];
  setSearch: (search: string) => void;
  toggleScope: (name: string) => void;
};

export function EditGroupPermissions(props: EditGroupScopesProps): JSX.Element {
  return (
    <>
      <div className="relative">
        <div className="flex gap-2 absolute right-0 top-[-3rem]">
          <SearchBar onChange={(event) => props.setSearch(event.target.value)} />
        </div>
      </div>
      <div className="flex h-full overflow-auto border border-gray-200 rounded-b-lg rounded-r-lg">
        <GroupScopesRenderer scopes={props.scopes} claims={props.claims} toggleScope={props.toggleScope} />
        <GroupClaimsRenderer
          claims={props.claims}
          getEnabledScopesByClaim={props.getEnabledScopesByClaim}
        />
      </div>
    </>
  );
}
