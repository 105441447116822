import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export interface ErrorProps {
  message: string;
}

export function ErrorContainer(props: ErrorProps): JSX.Element {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">Error</h3>
          <div className="mt-2 text-sm text-red-700">
            <p>{props.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
