import React, { PropsWithChildren, Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';

type TooltipProps = PropsWithChildren<{
  message: JSX.Element;
  show: boolean;
  setShow: (show: boolean) => void;
}>;

export function Tooltip(props: PropsWithChildren<TooltipProps>): JSX.Element {
  return (
    <Popover className="relative">
      <div onMouseEnter={() => props.setShow(true)} onMouseLeave={() => props.setShow(false)}>
        {props.children}
      </div>
      <div className="-translate-x-80 -translate-y-9">
        <Transition
          as={Fragment}
          show={props.show}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-x-16"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-16"
        >
          <Popover.Panel
            onMouseEnter={() => props.setShow(true)}
            onMouseLeave={() => props.setShow(false)}
            className={'absolute -top-full flex w-screen max-w-min px-4 z-50'}
          >
            <div className="relative w-[16rem] shrink rounded-xl bg-white p-4 text-sm leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
              {props.message}
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
}
