import React from 'react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid';

import { User, Group } from '@5minds/processcube_authority_sdk';
import { SearchBar } from '../../../components';
import { sortByUserName } from '../../../infrastructure';

import { SelectUserRenderer } from './SelectUserRenderer';

type SelectUsersRendererProps = {
  currentGroup: Group;
  unselectedUsers: User[];
  addUserToGroup: (user: User) => void;
  removeUserFromGroup: (user: User) => void;
  setUserFilter: (filter: string) => void;
};

export function SelectUsersRenderer(props: SelectUsersRendererProps): JSX.Element {
  const currentUnarchivedUsers = props.currentGroup.users.filter((user) => !user.isArchived);
  const unselectedUnarchivedUsers = props.unselectedUsers.filter((user) => !user.isArchived);

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex my-4 px-2 justify-between gap-2 items-center">
          <h2 className="text-2xl font-bold leading-8 text-gray-900 truncate">
            Users of {<span className="text-5minds-blue-900">{props.currentGroup.name}</span>}
          </h2>
          <div className="flex gap-2">
            <div className="hidden w-36 lg:block">
              <SearchBar onChange={(event) => props.setUserFilter(event.target.value)} />
            </div>
          </div>
        </div>
        <div tabIndex={-1} className="flex flex-col h-full px-2 border-b border-t border-gray-200 overflow-auto">
          <div className="divide-y divide-gray-200">
            {currentUnarchivedUsers.sort(sortByUserName).map((user) => (
              <SelectUserRenderer
                key={`${user.username}`}
                showValue={user.username}
                action={
                  <a
                    className="text-red-700 hover:text-red-800 hover:cursor-pointer rounded-full focus:outline-none focus:ring-2 focus:ring-red-900"
                    onClick={() => props.removeUserFromGroup(user)}
                    title="Remove User from Group"
                    tabIndex={0}
                  >
                    <MinusCircleIcon className="h-8 w-8" aria-hidden="true" id="minusIconFromGroupsTable" />
                  </a>
                }
              />
            ))}
            {unselectedUnarchivedUsers.sort(sortByUserName).map((user) => (
              <SelectUserRenderer
                key={`${user.username}`}
                showValue={user.username}
                action={
                  <a
                    className="text-5minds-blue-800 hover:text-5minds-blue-900 hover:cursor-pointer rounded-full focus:outline-none focus:ring-2 focus:ring-5minds-blue-900"
                    onClick={() => props.addUserToGroup(user)}
                    title="Add User to Group"
                    tabIndex={0}
                  >
                    <PlusCircleIcon className="h-8 w-8" aria-hidden="true" id="plusIconFromGroupsTable" />
                  </a>
                }
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
