import debounce from 'lodash.debounce';
import React, { useState, useEffect, useCallback } from 'react';
import { SearchBar } from '../../../components';
import { FrontendGroup } from '../../../contracts';
import { filterGroups } from '../../../infrastructure';
import { UserGroupsRenderer } from './UserGroupsRenderer';
import { Group, User } from '@5minds/processcube_authority_sdk';

type EditUserGroupsProps = {
  groups: FrontendGroup[];
  addUserToGroup: (group: FrontendGroup, callback?: (updatedUser: User) => void) => void;
  removeUserFromGroup: (group: FrontendGroup, callback?: (updatedUser: User) => void) => void;
};

export function EditUserGroups(props: EditUserGroupsProps): JSX.Element {
  const [groups, setGroups] = useState<FrontendGroup[]>(props.groups);

  const [filteredGroups, setFilteredGroups] = useState<FrontendGroup[]>(groups);
  const [search, setSearch] = useState<string>('');

  function toggleGroup(group: FrontendGroup) {
    if (group.enabled) {
      props.removeUserFromGroup(group, (updatedUser: User) => {
        group.enabled = false;
        setGroups([...groups]);
      });
      return;
    }

    props.addUserToGroup(group, (updatedUser: User) => {
      group.enabled = true;
      setGroups([...groups]);
    });
  }

  useEffect(() => {
    const filteredGroups = filterGroups(search, groups);
    setFilteredGroups(filteredGroups);
  }, [groups]);

  useEffect(() => {
    debouncedFilter(search);
  }, [search]);

  const debouncedFilter = useCallback(
    debounce((search: string) => {
      const filteredGroups = filterGroups(search, groups);
      setFilteredGroups(filteredGroups);
    }, 250),
    []
  );

  return (
    <>
      <div className="relative">
        <div className="absolute right-5 top-1 z-50">
          <SearchBar onChange={(event) => setSearch(event.target.value)} />
        </div>
      </div>
      <div className="flex h-full overflow-auto border border-gray-200 border-t-transparent rounded-b-lg">
        <UserGroupsRenderer groups={filteredGroups} toggleGroup={toggleGroup} />
      </div>
    </>
  );
}
