import React, { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';

import { Claim, Group, Scope } from '@5minds/processcube_authority_sdk';
import { FrontendUserClaim, FrontendScope, FrontendGroup } from '../../../contracts';
import { WithDefaultNavBar, WithSteps, StepStatus, Step, ErrorNotification } from '../../../components';
import { parseGroups, parseUserClaims, parseUserScopes, sortByName } from '../../../infrastructure';

import { AddUserAccount } from './AddUserAccount';
import { AddUserConfirm } from './AddUserConfirm';
import { AddUserPermissions } from './AddUserPermissions';
import { AddUserGroups } from './AddUserGroups';

type AddUserPageProps = {
  routerPrefix: string;
  logo: string;
  issuerUrl: string;
  groups: Group[];
  scopes: Scope[];
  claims: Claim[];
  scopelessClaims: Claim[];
};

export function AddUserPage(props: AddUserPageProps): JSX.Element {
  const [steps, setSteps] = useState<Step[]>([
    {
      id: 'Step 1',
      name: 'Account',
      status: StepStatus.CURRENT,
    },
    {
      id: 'Step 2',
      name: 'Groups',
      status: StepStatus.UPCOMING,
    },
    {
      id: 'Step 3',
      name: 'Permissions',
      status: StepStatus.UPCOMING,
    },
    {
      id: 'Step 4',
      name: 'Confirm',
      status: StepStatus.UPCOMING,
    },
  ]);

  const parsedGroups = parseGroups(props.groups);
  const parsedScopes = parseUserScopes(props.scopes.sort(sortByName));
  const parsedClaims = parseUserClaims(props.claims);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [groups, setGroups] = useState<FrontendGroup[]>(parsedGroups);
  const [scopes, setScopes] = useState<FrontendScope[]>(parsedScopes);
  const [claims, setClaims] = useState<FrontendUserClaim[]>(parsedClaims);

  const [error, setError] = useState<string | null>(null);

  const debouncedUpdateDetails = useCallback(debounce(updateDetails, 100), []);
  const debouncedUpdateGroups = useCallback(debounce(updateGroups, 100), []);
  const debouncedUpdatePermissions = useCallback(debounce(updatePermissions, 100), []);

  function updateDetails(username: string, password: string) {
    setUsername(username);
    setPassword(password);
  }

  function updateGroups(groups: FrontendGroup[]) {
    setGroups(groups);
    const enabledGroups = groups.filter((group) => group.enabled);
    const scopeNamesFromEnabledGroups = enabledGroups.flatMap((group) => group.scopeNames);
    scopes.forEach((scope) => {
      const scopeFromGroup = scopeNamesFromEnabledGroups.includes(scope.name);
      scope.fromGroup = scopeFromGroup ? true : false;
    });
    updateClaims();
  }

  function updateClaims() {
    const enabledScopes = scopes.filter((scope) => scope.enabled || scope.fromGroup);
    const enabledClaimNames = enabledScopes.flatMap((scope) => scope.claims);
    claims.forEach((claim) => {
      claim.enabled = enabledClaimNames.includes(claim.name);
    });
  }

  function updatePermissions(scopes: FrontendScope[], claims: FrontendUserClaim[]) {
    setScopes(scopes);
    setClaims(claims);
  }

  function renderStepContent(name: string) {
    switch (name) {
      case 'Confirm':
        return (
          <AddUserConfirm
            routerPrefix={props.routerPrefix}
            user={{
              username,
              password,
              groups: groups.filter((g) => g.enabled),
              scopes: scopes.filter((s) => s.enabled),
              claims: claims.filter((c) => c.enabled),
            }}
            setError={setError}
          />
        );
      case 'Groups':
        return <AddUserGroups groups={groups} save={(groups) => debouncedUpdateGroups(groups)} />;
      case 'Permissions':
        return (
          <AddUserPermissions
            scopes={scopes}
            claims={claims}
            save={(scopes, claims) => debouncedUpdatePermissions(scopes, claims)}
          />
        );
      default:
        return (
          <AddUserAccount
            username={username}
            password={password}
            save={(username, password) => debouncedUpdateDetails(username, password)}
          />
        );
    }
  }

  return (
    <>
      <ErrorNotification message={error} setMessage={setError} />
      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <WithSteps steps={steps} setSteps={setSteps}>
          {renderStepContent(steps.find((s) => s.status === StepStatus.CURRENT)?.name || '')}
        </WithSteps>
      </WithDefaultNavBar>
    </>
  );
}
