import { UserManager } from 'oidc-client';
import { ClientMetadata } from 'oidc-provider';
import React, { useEffect } from 'react';
import { createUserManager } from '../infrastructure';

const getAccessTokenFromHash = (hash: string): string | null => {
  hash = hash.replace(/^#/, '');
  const params = new URLSearchParams(hash);
  return params.get('access_token');
};

const getIdTokenFromHash = (hash: string): string | null => {
  hash = hash.replace(/^#/, '');
  const params = new URLSearchParams(hash);
  return params.get('id_token');
};

const setTokenCookies = async (routerPrefix: string, accessToken: string, idToken: string): Promise<void> => {
  await fetch(`${routerPrefix}/set_access_token_cookie`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      access_token: accessToken,
      id_token: idToken,
    }),
  });
};

const clearHashFromUrl = () => history.pushState('', document.title, window.location.pathname + window.location.search);

type CookieRedirectPageProps = {
  routerPrefix: string;
  clientData: ClientMetadata;
  redirectUri: string;
  issuerUrl: string;
};

export function CookieRedirectPage({
  clientData,
  redirectUri,
  issuerUrl,
  routerPrefix,
}: CookieRedirectPageProps): JSX.Element {
  useEffect(() => {
    const access_token = getAccessTokenFromHash(window.location.hash);
    const id_token = getIdTokenFromHash(window.location.hash);
    clearHashFromUrl();

    if (access_token && id_token) {
      setTokenCookies(routerPrefix, access_token, id_token).then(
        () => (window.location.href = '/acr/username_password/admin'),
      );
    } else {
      const userManager = createUserManager(clientData, redirectUri, issuerUrl);
      userManager.signinRedirect();
    }
  }, []);

  return <div>Redirecting...</div>;
}
