import React, { useRef } from 'react';

import { ClaimTypes } from '@5minds/processcube_authority_sdk';
import { Modal } from '../../../components';

type CreateClaimDialogProps = {
  show: boolean;
  createClaim: (name?: string, description?: string, type?: ClaimTypes) => void;
  hide: () => void;
};

export function CreateClaimDialog(props: CreateClaimDialogProps): JSX.Element {
  const types: string[] = Object.values(ClaimTypes);

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const typeRef = useRef<HTMLSelectElement>(null);

  const submit = () => {
    const name = nameRef.current?.value;
    const description = descriptionRef.current?.value;
    const type = typeRef.current?.value;

    props.createClaim(name, description, type as ClaimTypes);
  };

  return (
    <Modal show={props.show} hide={props.hide}>
      <div className="p-4">
        <div className="border-b border-gray-200 bg-white pb-2 mb-4">
          <h2 className="text-2xl font-bold leading-6 text-gray-900">Create Claim</h2>
        </div>
        <div className="flex flex-col gap-3">
          <div>
            <label htmlFor="claim-name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div className="mt-1">
              <input
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-5minds-blue-900 focus:ring-5minds-blue-900 sm:text-sm"
                id="claim-name-in-claim-create"
                ref={nameRef}
                type="text"
              />
            </div>
          </div>
          <div>
            <label htmlFor="claim-type" className="block text-sm font-medium text-gray-700">
              Type
            </label>
            <div className="mt-1">
              <select
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={types[0]}
                id="claim-type-in-claim-create"
                name="claim-type-in-claim-create"
                ref={typeRef}
              >
                {types.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="claim-description" className="block text-sm font-medium leading-6 text-gray-900">
              Description
            </label>
            <div className="mt-1">
              <textarea
                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-5minds-blue-900 sm:py-1.5 sm:text-sm sm:leading-6"
                id="claim-description-in-claim-create"
                ref={descriptionRef}
                rows={4}
              />
            </div>
          </div>
          <button
            className="w-40 ml-auto inline-flex justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm  focus:ring-5minds-blue-900 border-transparent text-white bg-5minds-blue-800 hover:bg-5minds-blue-900 sm:col-start-2"
            onClick={submit}
          >
            Create
          </button>
        </div>
      </div>
    </Modal>
  );
}
