import React, { PropsWithChildren } from 'react';

interface CardProps {
  header: React.ReactNode;
}

export function Card(props: PropsWithChildren<CardProps>): JSX.Element {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md border border-gray-300 min-w-[24rem] max-w-xl w-1/4">
      <div className="px-4 py-5 sm:px-6">{props.header}</div>
      <div className="px-4 py-5 sm:p-6">{props.children}</div>
    </div>
  );
}
