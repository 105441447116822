import React, { useState } from 'react';

import { FrontendGroup } from '../../../contracts';
import { IdRenderer, TextValueInput } from '../../../components';

type EditGroupDetailsProps = {
  routerPrefix: string;
  group: FrontendGroup;
  updateGroupDetails: (groupName: string, description?: string) => void;
};

export function EditGroupDetails(props: EditGroupDetailsProps): JSX.Element {
  const [groupName, setGroupName] = useState(props.group.name);
  const [description, setDescription] = useState(props.group.description);

  return (
    <div className="p-4 border border-gray-200 rounded-b-lg rounded-r-lg">
      <div className="border-b border-gray-200 bg-white pb-2 mb-4">
        <h2 className="text-2xl font-bold leading-6 text-gray-900">Edit {props.group.name}</h2>
      </div>
      <div className="flex flex-col gap-3">
        <IdRenderer title="GroupId" id={props.group.id} />
        <TextValueInput
          name="groupname"
          title="Groupname"
          textValue={props.group.name}
          setTextValue={(v) => setGroupName(v)}
        />
        <TextValueInput
          name="groupdescription"
          title="Group Description"
          textValue={props.group.description}
          setTextValue={(v) => setDescription(v)}
        />
        <button
          type="submit"
          className="w-40 ml-auto inline-flex justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm  focus:ring-5minds-blue-900 border-transparent text-white bg-5minds-blue-800 hover:bg-5minds-blue-900 sm:col-start-2"
          onClick={() => props.updateGroupDetails(groupName, description)}
        >
          Save
        </button>
      </div>
    </div>
  );
}
