import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { FrontendUserClaim } from '../../../contracts';
import { ToggleSwitch, Tooltip } from '../../../components';

type UserClaimEditorProps = {
  claim: FrontendUserClaim;
  updateClaim: (value: any) => void;
};

const MonacoEditor = Editor;

export function UserClaimEditor(props: UserClaimEditorProps): JSX.Element {
  const [showTooltip, setShowTooltip] = useState(false);
  const [claimValue, setClaimValue] = useState(props.claim.value);
  let editor: any = null;

  useEffect(() => {
    if (claimValue !== props.claim.value) {
      props.updateClaim(claimValue);
    }
  }, [claimValue]);

  switch (props.claim.type) {
    case 'boolean':
      editor = (
        <ToggleSwitch
          id={`${props.claim.name}-value`}
          aria-describedby="claim-value"
          checked={props.claim.enabled && props.claim.value}
          onToggle={() => props.updateClaim(!props.claim.value)}
          disabled={!props.claim.enabled}
        />
      );
      break;
    case 'string':
      editor = (
        <input
          id={`${props.claim.name}-value`}
          aria-describedby="claim-value"
          type="text"
          inputMode="text"
          className="block max-w-lg w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-5minds-blue-900 sm:text-sm sm:leading-6  disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-600 disabled:ring-gray-300"
          disabled={!props.claim.enabled}
          placeholder="Value"
          value={claimValue}
          onChange={(event) => setClaimValue(event.target.value)}
        />
      );
      break;
    case 'int':
      editor = (
        <input
          id={`${props.claim.name}-value`}
          aria-describedby="claim-value"
          type="number"
          inputMode="numeric"
          className="block max-w-lg w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-5minds-blue-900 sm:text-sm sm:leading-6  disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-600 disabled:ring-gray-300"
          disabled={!props.claim.enabled}
          placeholder="Value"
          value={claimValue}
          onChange={(event) => setClaimValue(parseInt(event.target.value))}
        />
      );
      break;
    case 'object':
      editor = (
        <MonacoEditor
          height={props.claim.enabled ? undefined : '100%'}
          width={props.claim.enabled ? undefined : '100%'}
          language="json"
          defaultValue={JSON.stringify(props.claim.value) ?? '{}'}
          onChange={(value) => setClaimValue(value ? JSON.parse(value) : undefined)}
        />
      );
      break;
    default:
      editor = <></>;
      break;
  }

  if (props.claim.enabled) {
    return editor;
  }

  return (
    <Tooltip message={<p>Disabled claims can't be edited.</p>} show={showTooltip} setShow={setShowTooltip}>
      <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
        {editor}
      </div>
    </Tooltip>
  );
}
