import { UserManager } from 'oidc-client';
import { FrontendUserClaim, FrontendScope, FrontendClaim, FrontendGroup } from '../contracts';
import { ClientMetadata } from 'oidc-provider';
import { Claim, Scope, Group, User, UserClaim, createDefaultClaimValue } from '@5minds/processcube_authority_sdk';

export function parseUserScopes(scopes: Scope[], scopesFromGroup?: Scope[], user?: User): FrontendScope[] {
  return scopes
    .map((scope) => {
      const fromGroup = scopesFromGroup ? scopesFromGroup.map((scope) => scope.id).includes(scope.id) : undefined;
      const directlyEnabled = user?.scopes.map((s) => s.id).includes(scope.id) ?? false;
      const enabledFromGroup = user?.scopesFromGroups.map((s) => s.id).includes(scope.id) ?? false;
      const enabled = directlyEnabled || enabledFromGroup;
      const claims = scope.claims.sort(sortByName).map((c) => c.name);
      return {
        ...scope,
        fromGroup: fromGroup,
        enabled: enabled,
        claims: claims,
      };
    })
    .sort(sortByName);
}

export function parseGroupScopes(scopes: Scope[], group?: Group): FrontendScope[] {
  return scopes
    .map((scope) => {
      const enabled = group?.scopes.map((s) => s.id).includes(scope.id) ?? false;
      const claims = scope.claims.sort(sortByName).map((c) => c.name);
      return {
        ...scope,
        fromGroup: true,
        enabled: enabled,
        claims: claims,
      };
    })
    .sort(sortByName);
}

export function parseGroupClaims(claims: Claim[], groupClaims?: Claim[]): FrontendClaim[] {
  const parsedClaims: FrontendClaim[] = [];

  for (const claim of claims) {
    if (parsedClaims.find((c) => c.id === claim.id)) continue;

    const matchingClaim = groupClaims?.find((c) => c.id === claim.id);
    const enabled = matchingClaim ? true : false;

    parsedClaims.push({
      ...claim,
      enabled,
    });
  }

  return parsedClaims.sort(sortByName);
}

export function parseUserClaims(claims: Claim[], userClaims?: UserClaim[]): FrontendUserClaim[] {
  const parsedClaims: FrontendUserClaim[] = [];

  for (const claim of claims) {
    if (parsedClaims.find((c) => c.name === claim.name)) continue;

    const matchingClaim = userClaims?.find((c) => c.name === claim.name);
    const enabled = matchingClaim ? true : false;
    const value = matchingClaim?.value ?? createDefaultClaimValue(claim.type);

    parsedClaims.push({
      ...claim,
      value,
      enabled,
    });
  }

  return parsedClaims.sort(sortByName);
}

export function parseGroups(groups: Group[]): FrontendGroup[] {
  if (!groups) {
    return [];
  }

  const parsedGroups = groups.map((group) => {
    const scopeNames = group.scopes.map((scope) => scope.name);
    return {
      id: group.id,
      name: group.name,
      scopeNames: scopeNames,
      description: group.description,
    };
  });

  return parsedGroups.sort(sortByName);
}

export function parseUserGroups(groups: Group[], userGroupNames: string[]): FrontendGroup[] {
  if (!groups) {
    return [];
  }
  const parsedGroups = groups.map((group) => {
    const enabled = userGroupNames.includes(group.name);
    const scopeNames = group.scopes.map((scope) => scope.name);
    return {
      id: group.id,
      name: group.name,
      scopeNames: scopeNames,
      description: group.description,
      enabled: enabled,
    };
  });

  return parsedGroups.sort(sortByName);
}

export function filterGroups(search: string, groups: FrontendGroup[]): FrontendGroup[] {
  return groups.filter(
    (group) =>
      group.name.toLowerCase().includes(search.toLowerCase()) ||
      group.description?.toLowerCase().includes(search.toLowerCase()),
  );
}

export function filterScopes(search: string, scopes: FrontendScope[], includeClaimNames: string[]): FrontendScope[] {
  return scopes.filter(
    (scope) =>
      scope.name.toLowerCase().includes(search.toLowerCase()) ||
      scope.claims.some((claim) => includeClaimNames.find((includeClaimName) => includeClaimName === claim)),
  );
}

export function filterUserClaims(search: string, claims: FrontendUserClaim[]): FrontendUserClaim[] {
  return claims.filter(
    (claim) =>
      claim.name.toLowerCase().includes(search.toLowerCase()) ||
      claim.value.toString().toLowerCase().includes(search.toLowerCase()),
  );
}

export function filterClaims(search: string, claims: FrontendClaim[]): FrontendClaim[] {
  return claims.filter((claim) => claim.name.toLowerCase().includes(search.toLowerCase()));
}

export function getUrlParam(key: string): string | null {
  const url = new URL(window.location.href);
  return url.searchParams.get(key);
}

export function updateUrlParam(key: string, value: string): void {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.replaceState({}, '', url.toString());
}

export function setUrlParam(key: string, value: string): void {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  searchParams.forEach((_, key) => {
    url.searchParams.delete(key);
  });

  url.searchParams.set(key, value);

  window.history.replaceState({}, '', url.toString());
}

export function parseUserToPrettyString(user: User): string {
  const scopes: string[] = user.scopes.map((scope) => scope.name);
  const claims: Record<string, any> = user.claims.reduce(
    (acc, claim) => {
      acc[claim.name] = claim.value;
      return acc;
    },
    {} as Record<string, any>,
  );
  return JSON.stringify({ username: user.username, scopes, claims }, null, 2);
}

export function parseGroupToPrettyString(group: Group): string {
  const scopes: string[] = group.scopes.map((scope) => scope.name);
  const claims: string[] = group.claims.map((claim) => claim.name);
  return JSON.stringify({ groupName: group.name, scopes, claims }, null, 2);
}

export function sortByName(a: { name: string }, b: { name: string }): number {
  return a.name.localeCompare(b.name);
}

export function sortByUserName(a: { username: string }, b: { username: string }): number {
  return a.username.localeCompare(b.username);
}

export function createUserManager(clientData: ClientMetadata, redirectUri: string, issuerUrl: string) {
  const responseType = clientData.response_types == null ? 'id_token token' : clientData.response_types[0];
  const scope = clientData.scope == null ? 'openid profile email' : clientData.scope;

  return new UserManager({
    authority: issuerUrl,
    client_id: clientData.client_id,
    client_secret: clientData.client_secret,
    redirect_uri: redirectUri,
    response_type: responseType,
    scope: scope,
  });
}
