const MAGIC_ID_PRVOIDER_FORM_ID = 'op.logoutForm';

import { Card } from '../../../components';

export type SessionEndPageProps = {
  htmlForm: string;
};

export function SessionEndPage({ htmlForm }: SessionEndPageProps): JSX.Element {
  const logout = (): void => {
    const form = document.getElementById(MAGIC_ID_PRVOIDER_FORM_ID) as HTMLFormElement;

    const logoutIndicator = document.createElement('input');
    logoutIndicator.type = 'hidden';
    logoutIndicator.name = 'logout';
    logoutIndicator.value = 'yes';
    form.appendChild(logoutIndicator);

    form.submit();
  };

  const abort = (): void => {
    const form = document.getElementById(MAGIC_ID_PRVOIDER_FORM_ID) as HTMLFormElement;
    form.submit();
  };

  return (
    <Card header={<h2 className="text-2xl font-medium leading-6 text-gray-900">Continue Logout?</h2>}>
      <div dangerouslySetInnerHTML={{ __html: htmlForm }} />
      <div className="flex flex-row justify-between gap-10">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm border-gray-300 focus:ring-5minds-blue-900 hover:bg-gray-50 sm:col-start-2"
          onClick={abort}
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm border-transparent bg-red-100 text-red-700 hover:bg-red-200 focus:ring-red-500 sm:col-start-1 sm:mt-0"
          onClick={logout}
        >
          Logout
        </button>
      </div>
    </Card>
  );
}
