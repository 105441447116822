import React, { useState } from 'react';

import { FrontendUser } from '../../../contracts';
import { PasswordInput, IdRenderer, TextValueInput } from '../../../components';

type EditUserAccountProps = {
  routerPrefix: string;
  user: FrontendUser;
  updateUserDetails: (username: string, password: string) => void;
};

export function EditUserAccount(props: EditUserAccountProps): JSX.Element {
  const [username, setUsername] = useState(props.user.username);
  const [password, setPassword] = useState('');

  return (
    <div className="p-4 border border-gray-200 rounded-b-lg rounded-r-lg">
      <div className="border-b border-gray-200 bg-white pb-2 mb-4">
        <h2 className="text-2xl font-bold leading-6 text-gray-900">Edit {props.user.username}</h2>
      </div>
      <div className="flex flex-col gap-3">
        <IdRenderer title="AccountId" id={props.user.accountId} />
        <TextValueInput
          name="username"
          title="Username"
          textValue={props.user.username}
          setTextValue={(u) => setUsername(u)}
        />
        <PasswordInput password={password} setPassword={(p) => setPassword(p)} />
        <button
          type="submit"
          className="w-40 ml-auto inline-flex justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm  focus:ring-5minds-blue-900 border-transparent text-white bg-5minds-blue-800 hover:bg-5minds-blue-900 sm:col-start-2"
          onClick={() => props.updateUserDetails(username, password)}
        >
          Save
        </button>
      </div>
    </div>
  );
}
