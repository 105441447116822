import React, { PropsWithChildren, useEffect, useState } from 'react';

export enum StepStatus {
  COMPLETE,
  CURRENT,
  UPCOMING,
}

export type Step = {
  id: string;
  name: string;
  status: StepStatus;
};

type StepsProps = {
  steps: Step[];
  setSteps: (steps: Step[]) => void;
};

export function WithSteps(props: PropsWithChildren<StepsProps>): JSX.Element {
  return (
    <div className="flex flex-col w-full h-full overflow-y-auto max-w-7xl mx-auto">
      <Steps steps={props.steps} setSteps={props.setSteps} />
      {props.children}
    </div>
  );
}

export function Steps({ steps, setSteps }: StepsProps): JSX.Element {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  useEffect(() => {
    const currentStep = steps.find((s) => s.status === StepStatus.CURRENT);
    if (!currentStep) return;
    const index = steps.indexOf(currentStep);
    setCurrentStepIndex(index);
  }, [steps]);

  useEffect(() => {
    setShowBackButton(currentStepIndex > 0);
    setShowNextButton(currentStepIndex < steps.length - 1);
  }, [currentStepIndex, steps]);

  function onClick(step: Step): void {
    const stepIndex = steps.findIndex((s) => s.id === step.id);
    const previousSteps = steps.slice(0, stepIndex);
    const nextSteps = steps.slice(stepIndex + 1);

    const newSteps: Step[] = [
      ...previousSteps.map((s) => ({ ...s, status: StepStatus.COMPLETE })),
      {
        ...step,
        status: StepStatus.CURRENT,
      },
      ...nextSteps.map((s) => ({ ...s, status: StepStatus.UPCOMING })),
    ];

    setSteps(newSteps);
  }

  return (
    <>
      {showBackButton || showNextButton ? (
        <div className="flex w-full p-4">
          {showBackButton && (
            <button
              className="w-40 inline-flex justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm  focus:ring-5minds-blue-900 border-transparent text-white bg-5minds-blue-800 hover:bg-5minds-blue-900 sm:col-start-2"
              onClick={() => onClick(steps[currentStepIndex - 1])}
            >
              Back
            </button>
          )}
          {showNextButton && (
            <button
              className="w-40 ml-auto inline-flex justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm  focus:ring-5minds-blue-900 border-transparent text-white bg-5minds-blue-800 hover:bg-5minds-blue-900 sm:col-start-2"
              onClick={() => onClick(steps[currentStepIndex + 1])}
            >
              Next
            </button>
          )}
        </div>
      ) : null}
      <nav className="p-4 border border-gray-200 rounded-t-lg" aria-label="Progress">
        <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
          {steps.map((step) => (
            <li key={step.name} className="md:flex-1">
              {step.status === StepStatus.COMPLETE ? (
                <a
                  className="group flex flex-col border-l-4 border-5minds-blue-900 py-2 pl-4 hover:border-5minds-blue-900 hover:cursor-pointer md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  onClick={() => onClick(step)}
                >
                  <span className="text-sm font-medium text-5minds-blue-900 group-hover:text-5minds-blue-900">
                    {step.id}
                  </span>
                  <span className="font-semibold">{step.name}</span>
                </a>
              ) : step.status === StepStatus.CURRENT ? (
                <a
                  className="flex flex-col border-l-4 border-5minds-blue-700 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  onClick={() => onClick(step)}
                  aria-current="step"
                >
                  <span className="text-sm font-medium text-5minds-blue-900">{step.id}</span>
                  <span className="font-semibold">{step.name}</span>
                </a>
              ) : (
                <a
                  className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 hover:cursor-pointer md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  onClick={() => onClick(step)}
                >
                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                  <span className="font-semibold">{step.name}</span>
                </a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
}
