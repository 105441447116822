import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

import { Group } from '@5minds/processcube_authority_sdk';
import { SearchBar } from '../../../components';
import { SelectGroupRenderer } from './SelectGroupRenderer';

type SelectGroupRendererProps = {
  groups: Group[];
  currentGroup: Group | null;
  routerPrefix: string;
  setCurrentGroup: (group: Group) => void;
  setGroupFilter: (filter: string) => void;
  setSelectedGroup: (group: Group) => void;
  showCreateDialog: () => void;
  showDeleteDialog: () => void;
  showUpdateDialog: () => void;
};

export function SelectGroupsRenderer(props: SelectGroupRendererProps) {
  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex my-4 px-2 justify-between gap-2 items-center">
          <h2 className="text-2xl font-bold leading-8 text-gray-900">Groups</h2>
          <div className="flex gap-2">
            <button
              type="button"
              className="relative inline-flex items-center gap-x-1.5 rounded-md bg-5minds-blue-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-5minds-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-5minds-blue-900"
              onClick={props.showCreateDialog}
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
              <div className="hidden lg:block">Create Group</div>
            </button>
            <div className="hidden w-36 lg:block">
              <SearchBar onChange={(event) => props.setGroupFilter(event.target.value)} />
            </div>
          </div>
        </div>
        <nav tabIndex={-1} className="flex flex-col h-full px-4 pt-2 border-t border-gray-200 overflow-auto">
          <ul role="list" className="flex flex-col gap-y-7">
            <li className="flex flex-col">
              <ul role="list" className="flex flex-col gap-1 -mx-2 space-y-1">
                {props.currentGroup !== null &&
                  props.groups.map((group) => (
                    <SelectGroupRenderer
                      key={group.name}
                      group={group}
                      currentGroup={props.currentGroup!}
                      routerPrefix={props.routerPrefix}
                      setCurrentGroup={props.setCurrentGroup}
                      setSelectedGroup={props.setSelectedGroup}
                      showDeleteDialog={props.showDeleteDialog}
                      showUpdateDialog={props.showUpdateDialog}
                    />
                  ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
