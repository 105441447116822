import React from 'react';
import pino from 'pino';
import { PagePayload, ExtensionMetadata } from '@5minds/processcube_authority_sdk';

import upeFrontendExport from './internal_extensions/username_password';

import { SessionEndPage, SessionEndSuccessPage, ErrorPage, ConsentPage } from './pages';

import './App.scss';

type AuthorityPayloadObject = {
  pageTitle: string;
  upeEnabled: boolean;
  frontendExtensions: [
    {
      metadata: ExtensionMetadata;
      exports: () => {
        default: {
          onLoad?: (args: { componentRegistry: ComponentRegistry }) => void;
        };
      };
    }
  ];
};

type DynamicRendererComponent = React.ComponentType<{ name: string }>;
type DynamicRenderedComponent = React.ComponentType<{ dynamicRenderer?: DynamicRendererComponent } & any>;

export type PropsWithDynamicRenderer = {
  dynamicRenderer: DynamicRendererComponent;
};

const logger = pino();
logger.level = process.env.LOG_LEVEL || 'info';

class ComponentRegistry {
  private components: Map<string, DynamicRenderedComponent> = new Map();

  public register(name: string, component: DynamicRenderedComponent): void {
    logger.debug('register', name, component);
    this.components.set(name, component);
  }

  public get(name: string): DynamicRenderedComponent | undefined {
    return this.components.get(name);
  }

  public getDynamicRenderer(): DynamicRendererComponent {
    const DynamicRenderer: DynamicRendererComponent = ({ name }) => {
      const Component = this.get(name);
      if (!Component) {
        return (
          <div>
            <p>Missing component {name}</p>
          </div>
        );
      }

      const childDynamicRenderer: DynamicRendererComponent = ({ name: childName }) => {
        return <DynamicRenderer name={`${name}/${childName}`} />;
      };

      return <Component {...payload.props} dynamicRenderer={childDynamicRenderer} />;
    };

    return DynamicRenderer;
  }
}

const authority = (window as any).__$authority as AuthorityPayloadObject;

if (!authority) {
  throw new Error('Authority is not defined');
}

if (authority.pageTitle) {
  document.title = authority.pageTitle;
}

const payload = (window as any).__$payload as PagePayload;
if (!payload) {
  throw new Error('Payload is not defined');
}

export const components = new ComponentRegistry();

components.register('app', DefaultApp);

components.register('page:session_end', SessionEndPage);
components.register('page:session_end_success', SessionEndSuccessPage);
components.register('page:error', ErrorPage);
components.register('page:consent', ConsentPage);

const DynamicRenderer = components.getDynamicRenderer();

const extensionsRequire = (name: string): any => {
  if (name === 'react') {
    return React;
  }
  console.warn('Call to require:', name);
};

(window as any).require = extensionsRequire;

authority.frontendExtensions.forEach((extension) => {
  if (extension.exports) {
    const loadedModule = extension.exports();
    logger.debug('loadedModule', loadedModule);
    if (loadedModule.default && loadedModule.default.onLoad) {
      loadedModule.default.onLoad({ componentRegistry: components });
    }
  }
});

if (authority.upeEnabled) {
  upeFrontendExport.onLoad({ componentRegistry: components });
}

logger.debug(components);

function DefaultApp(): JSX.Element {
  return (
    <div className="centered-app">
      <DynamicRenderer name={`page:${payload.targetPage}` as string} />
    </div>
  );
}

(window as any).showAuthorityDebuggingInformation = () => {
  logger.debug('Authority', authority);
  logger.debug('Payload', payload);
};

export function App(): JSX.Element {
  return <DynamicRenderer name={'app'} />;
}
