import React from 'react';

type PasswordInputProps = {
  password?: string;
  setPassword?: (password: string) => void;
};

export function PasswordInput(props: PasswordInputProps): JSX.Element {
  return (
    <div>
      <label htmlFor="password" className="text-input-label">
        Password
      </label>
      <div className="mt-1">
        <input
          type="password"
          name="password"
          className="text-input"
          defaultValue={props.password}
          onChange={(e) => props.setPassword?.(e.target.value)}
        />
      </div>
    </div>
  );
}
