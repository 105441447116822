import React from 'react';

type ListRendererProps = {
  title: string;
  items: Array<string>;
}

export function ListRenderer(props: ListRendererProps): JSX.Element {
  return (
    <div>
      <div className='border-b border-gray-200 bg-white py-1 flex flex-row gap-2'>
        <h3 className='text-lg font-medium leading-6 text-gray-900'>{props.title}</h3>
      </div>
      <div className='flex flex-col max-h-screen-0.5 overflow-auto px-2' tabIndex={-1}>
        {props.items.map((item) => (
          <div key={item} className='border-b border-gray-200 pb-0.5 mt-0.5'>
            <div className='flex flex-row gap-0.5'>
              <span className='ml-2 text-sm text-gray-500'>{item}</span>
            </div>
          </div>
        )
        )}
      </div>
    </div>
  );
}
