import React, { useCallback, useEffect, useState } from 'react';

import { Claim, Group, Scope, User, UserClaim } from '@5minds/processcube_authority_sdk';
import { FrontendUserClaim, FrontendScope, FrontendUser, FrontendGroup } from '../../../contracts';
import { ErrorNotification, SuccessNotification, Tab, WithDefaultNavBar, WithTabs } from '../../../components';
import {
  parseUserScopes,
  parseUserClaims,
  sortByName,
  filterUserClaims,
  filterScopes,
  parseGroups,
  parseUserGroups,
} from '../../../infrastructure';

import { EditUserAccount } from './EditUserAccount';
import { EditUserPermissions } from './EditUserPermissions';
import debounce from 'lodash.debounce';
import { EditUserGroups } from './EditUserGroups';

type EditUserPageProps = {
  routerPrefix: string;
  logo: string;
  issuerUrl: string;
  user: User;
  userGroupNames: string[];
  groups: Group[];
  scopes: Scope[];
  claims: Claim[];
  scopelessClaims: Claim[];
};

export function EditUserPage(props: EditUserPageProps): JSX.Element {
  const [tabs, setTabs] = useState<Tab[]>([
    {
      name: 'Account',
      href: 'account',
      current:
        new URLSearchParams(window.location.search).get('tab') === 'account' ||
        new URLSearchParams(window.location.search).get('tab') == null,
    },
    {
      name: 'Permissions',
      href: 'permissions',
      current: new URLSearchParams(window.location.search).get('tab') === 'permissions',
    },
    {
      name: 'Groups',
      href: 'groups',
      current: new URLSearchParams(window.location.search).get('tab') === 'groups',
    },
  ]);

  const parsedGroups: FrontendGroup[] = parseUserGroups(props.groups, props.userGroupNames);
  const parsedScopes: FrontendScope[] = parseUserScopes(
    props.scopes.sort(sortByName),
    props.user.scopesFromGroups,
    props.user
  );
  const parsedClaims: FrontendUserClaim[] = parseUserClaims(
    [...props.claims, ...props.scopelessClaims],
    props.user.claims
  );

  const [groups, setGroups] = useState<FrontendGroup[]>(parsedGroups);
  const [scopes, setScopes] = useState<FrontendScope[]>(parsedScopes);
  const [claims, setClaims] = useState<FrontendUserClaim[]>(parsedClaims);

  const [filteredScopes, setFilteredScopes] = useState<FrontendScope[]>(scopes);
  const [filteredClaims, setFilteredClaims] = useState<FrontendUserClaim[]>(claims);
  const [search, setSearch] = useState<string>('');

  const [user, setUser] = useState<FrontendUser>({ ...props.user, password: '' });
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  function updateUserData(user: User) {
    const parsedScopes: FrontendScope[] = parseUserScopes(props.scopes.sort(sortByName), user.scopesFromGroups, user);
    const parsedClaims: FrontendUserClaim[] = parseUserClaims([...props.claims, ...props.scopelessClaims], user.claims);

    setScopes(parsedScopes);
    setClaims(parsedClaims);
  }

  function updateUserDetails(name: string, password: string) {
    const body = {
      name,
      password,
    };

    fetch(`${props.routerPrefix}/admin/user/${user.username}/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedUser: User) => {
        setUser({
          ...user,
          username: updatedUser.username,
        });
        setSuccess('User details updated successfully');
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function addUserToGroup(group: FrontendGroup, callback?: (user: User) => void) {
    fetch(`${props.routerPrefix}/admin/user/${user.username}/add/group`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groupName: group.name }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedUser: User) => {
        updateUserData(updatedUser);
        if (callback) {
          callback(updatedUser);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  function removeUserFromGroup(group: FrontendGroup, callback?: (user: User) => void) {
    fetch(`${props.routerPrefix}/admin/user/${user.username}/remove/group`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groupName: group.name }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedUser: User) => {
        updateUserData(updatedUser);
        if (callback) {
          callback(updatedUser);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  function addUserScope(scope: FrontendScope, callback?: (userClaims: UserClaim[]) => void) {
    fetch(`${props.routerPrefix}/admin/user/${user.username}/add/scope`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scopeName: scope.name }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedClaims: UserClaim[]) => {
        if (callback) {
          callback(updatedClaims);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  function removeUserScope(scope: FrontendScope, callback?: (userClaims: UserClaim[]) => void) {
    fetch(`${props.routerPrefix}/admin/user/${user.username}/remove/scope`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scopeName: scope.name }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedClaims: UserClaim[]) => {
        if (callback) {
          callback(updatedClaims);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  function toggleScope(name: string) {
    const scope = scopes.find((s) => s.name === name);

    if (!scope) return;

    scope.enabled = !scope.enabled;

    if (scope.enabled) {
      addUserScope(scope, (userClaims: UserClaim[]) => {
        setScopes([...scopes]);
        setClaims(parseUserClaims([...props.claims, ...props.scopelessClaims], userClaims));
      });
    } else {
      removeUserScope(scope, (userClaims: UserClaim[]) => {
        setScopes([...scopes]);
        setClaims(parseUserClaims([...props.claims, ...props.scopelessClaims], userClaims));
      });
    }
  }

  function toggleClaim(name: string) {
    const claim = claims.find((c) => c.name === name);
    if (claim) {
      claim.enabled = !claim.enabled;
      setClaims([...claims]);
    }
  }

  function changeClaim(name: string, value: any) {
    const claim = claims.find((c) => c.name === name);
    if (!claim) return;

    fetch(`${props.routerPrefix}/admin/user/${user.username}/update/claim`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ claimName: claim.name, claimValue: value }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedClaim: UserClaim) => {
        claim.value = value;
        setClaims([...claims]);
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  function getEnabledScopesByClaim(claim: FrontendUserClaim): FrontendScope[] {
    return scopes.filter((scope) => scope.enabled && scope.claims.includes(claim.name));
  }

  function filterScopesAndClaims(filterTerm: string) {
    const filteredClaims = filterUserClaims(filterTerm, claims);
    const includedClaims = filteredClaims.map((c) => c.name.toLowerCase());
    const filteredScopes = filterScopes(filterTerm, scopes, includedClaims);
    setFilteredScopes(filteredScopes);
    setFilteredClaims(filteredClaims);
  }

  function renderTabContent(search: URLSearchParams) {
    const tab = search.get('tab');
    switch (tab) {
      case 'groups':
        return (
          <EditUserGroups groups={groups} addUserToGroup={addUserToGroup} removeUserFromGroup={removeUserFromGroup} />
        );
      case 'permissions':
        return (
          <EditUserPermissions
            claims={filteredClaims}
            scopes={filteredScopes}
            changeClaim={changeClaim}
            getEnabledScopesByClaim={getEnabledScopesByClaim}
            setSearch={setSearch}
            toggleClaim={toggleClaim}
            toggleScope={toggleScope}
          />
        );
      default:
        return <EditUserAccount routerPrefix={props.routerPrefix} user={user} updateUserDetails={updateUserDetails} />;
    }
  }

  const debouncedFilter = useCallback(
    debounce((search: string) => {
      filterScopesAndClaims(search);
    }, 250),
    []
  );

  useEffect(() => {
    filterScopesAndClaims(search);
  }, [scopes, claims]);

  useEffect(() => {
    debouncedFilter(search);
  }, [search]);

  return (
    <>
      <SuccessNotification message={success} setMessage={setSuccess} autoHide />
      <ErrorNotification message={error} setMessage={setError} />
      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <WithTabs tabs={tabs} setTabs={setTabs}>
          {renderTabContent(new URLSearchParams(window.location.search))}
        </WithTabs>
      </WithDefaultNavBar>
    </>
  );
}
