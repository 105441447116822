import React, { useRef } from 'react';

import { Modal } from '../../../components';

type CreateGroupDialogProps = {
  show: boolean;
  hide: () => void;
  createGroup: (name?: string, description?: string) => void;
};

export function CreateGroupDialog(props: CreateGroupDialogProps): JSX.Element {
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const submit = () => {
    const name = nameRef.current?.value;
    const description = descriptionRef.current?.value;

    props.createGroup(name, description);
  };

  return (
    <Modal show={props.show} hide={props.hide}>
      <div className="p-4">
        <div className="border-b border-gray-200 bg-white pb-2 mb-4">
          <h2 className="text-2xl font-bold leading-6 text-gray-900">Create Group</h2>
        </div>
        <div className="flex flex-col gap-3">
          <div>
            <label htmlFor="group-name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div className="mt-1">
              <input
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-5minds-blue-900 focus:ring-5minds-blue-900 sm:text-sm"
                id="group-name"
                ref={nameRef}
                type="text"
              />
            </div>
          </div>
          <div>
            <label htmlFor="group-description" className="block text-sm font-medium leading-6 text-gray-900">
              Description
            </label>
            <div className="mt-1">
              <textarea
                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-5minds-blue-900 sm:py-1.5 sm:text-sm sm:leading-6"
                id="group-description"
                ref={descriptionRef}
                rows={4}
              />
            </div>
          </div>
          <button
            className="w-40 ml-auto inline-flex justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm  focus:ring-5minds-blue-900 border-transparent text-white bg-5minds-blue-800 hover:bg-5minds-blue-900 sm:col-start-2"
            onClick={submit}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}
