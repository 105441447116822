import React from 'react';
import { FrontendUserClaim, FrontendScope } from '../../../contracts';
import { UserClaimRenderer } from './UserClaimRenderer';
import { OIDC_CLAIM_NAMES } from '../../../infrastructure';

type UserClaimsRendererProps = {
  claims: FrontendUserClaim[];
  toggleClaim: (name: string) => void;
  changeClaim: (name: string, value: any) => void;
  getEnabledScopesByClaim: (claim: FrontendUserClaim) => FrontendScope[];
};

export function UserClaimsRenderer(props: UserClaimsRendererProps): JSX.Element {
  const oidcClaimNames = OIDC_CLAIM_NAMES;
  const automaticallyCreatedOidcClaimNames = ['name'];
  const oidcClaims = props.claims.filter(
    (claim) => oidcClaimNames.includes(claim.name) && !automaticallyCreatedOidcClaimNames.includes(claim.name),
  );
  const customClaims = props.claims.filter(
    (claim) => !oidcClaimNames.includes(claim.name) && !automaticallyCreatedOidcClaimNames.includes(claim.name),
  );

  return (
    <div className="w-full h-full overflow-y-auto box-border p-4">
      <h2 className="mb-4 text-2xl font-bold leading-6 text-gray-900">Custom Claims</h2>
      <div className="flex flex-col gap-2">
        {customClaims.map((claim) => (
          <UserClaimRenderer
            key={claim.id}
            claim={claim}
            onChange={props.changeClaim}
            getEnabledScopesByClaim={props.getEnabledScopesByClaim}
          />
        ))}
      </div>
      <hr className="pb-4"></hr>
      <h2 className="mb-4 text-2xl font-bold leading-6 text-gray-900">OIDC-Claims</h2>
      <div className="flex flex-col gap-2 pb-4">
        {oidcClaims.map((claim) => (
          <UserClaimRenderer
            key={claim.id}
            claim={claim}
            onChange={props.changeClaim}
            getEnabledScopesByClaim={props.getEnabledScopesByClaim}
          />
        ))}
      </div>
    </div>
  );
}
