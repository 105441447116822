export type ErrorPageProps = {
  error: any;
};

export function ErrorPage({ error }: ErrorPageProps): JSX.Element {
  return (
    <div>
      <h1>Error Page</h1>
      <pre>{error}</pre>
    </div>
  );
}
