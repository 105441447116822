import React from 'react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/outline';

import { SearchBar } from '../../../components';
import { sortByName } from '../../../infrastructure';

import { SelectClaimRenderer } from './SelectClaimRenderer';
import { Scope, Claim } from '@5minds/processcube_authority_sdk';

type SelectClaimsRendererProps = {
  currentScope: Scope;
  unselectedClaims: Claim[];
  addClaimToScope: (claim: Claim) => void;
  removeClaimFromScope: (claim: Claim) => void;
  setClaimFilter: (filter: string) => void;
};

export function SelectClaimsRenderer(props: SelectClaimsRendererProps): JSX.Element {
  const isDefaultScope = props.currentScope.isDefaultScope;
  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex my-4 px-2 justify-between gap-2 items-center">
          <h2 className="text-2xl font-bold leading-8 text-gray-900 truncate">
            Claims of {<span className="text-5minds-blue-900">{props.currentScope.name}</span>}
          </h2>
          <div className="flex gap-2">
            <div className="hidden w-36 lg:block">
              <SearchBar onChange={(event) => props.setClaimFilter(event.target.value)} />
            </div>
          </div>
        </div>
        <div tabIndex={-1} className="flex flex-col h-full px-2 border-b border-t border-gray-200 overflow-auto">
          <div className="divide-y divide-gray-200">
            {props.currentScope.claims.sort(sortByName).map((claim) =>
              !isDefaultScope ? (
                <SelectClaimRenderer
                  key={`${claim.name}`}
                  claim={claim}
                  action={
                    <a
                      className="text-red-700 hover:text-red-800 hover:cursor-pointer rounded-full focus:outline-none focus:ring-2 focus:ring-red-900"
                      onClick={() => props.removeClaimFromScope(claim)}
                      title="Remove Claim from Scope"
                      tabIndex={0}
                    >
                      <MinusCircleIcon className="h-8 w-8" aria-hidden="true" />
                    </a>
                  }
                />
              ) : (
                <SelectClaimRenderer key={`${claim.name}`} claim={claim} action={<a></a>} />
              ),
            )}
            {!isDefaultScope &&
              props.unselectedClaims.sort(sortByName).map((claim) => (
                <SelectClaimRenderer
                  key={`${claim.name}`}
                  claim={claim}
                  action={
                    <a
                      className="text-5minds-blue-800 hover:text-5minds-blue-900 hover:cursor-pointer rounded-full focus:outline-none focus:ring-2 focus:ring-5minds-blue-900"
                      onClick={() => props.addClaimToScope(claim)}
                      title="Add Claim to Scope"
                      tabIndex={0}
                    >
                      <PlusCircleIcon className="h-8 w-8" aria-hidden="true" />
                    </a>
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
