import React, { useCallback, useEffect, useState } from 'react';

import { Claim, Scope, Group } from '@5minds/processcube_authority_sdk';
import { FrontendScope, FrontendGroup, FrontendClaim } from '../../../contracts';
import { ErrorNotification, SuccessNotification, Tab, WithDefaultNavBar, WithTabs } from '../../../components';
import { parseGroupScopes, parseGroupClaims, sortByName, filterScopes, filterClaims } from '../../../infrastructure';

import { EditGroupPermissions } from './EditGroupPermissions';
import debounce from 'lodash.debounce';
import { EditGroupDetails } from './EditGroupDetails';

type EditGroupPageProps = {
  routerPrefix: string;
  logo: string;
  issuerUrl: string;
  group: Group;
  scopes: Scope[];
  claims: Claim[];
  scopelessClaims: Claim[];
};

export function EditGroupPage(props: EditGroupPageProps): JSX.Element {
  const [tabs, setTabs] = useState<Tab[]>([
    {
      name: 'Details',
      href: 'details',
      current:
        new URLSearchParams(window.location.search).get('tab') === 'account' ||
        new URLSearchParams(window.location.search).get('tab') == null,
    },
    {
      name: 'Permissions',
      href: 'permissions',
      current: new URLSearchParams(window.location.search).get('tab') === 'permissions',
    },
  ]);

  const parsedScopes: FrontendScope[] = parseGroupScopes(props.scopes.sort(sortByName), props.group);
  const parsedClaims: FrontendClaim[] = parseGroupClaims(
    [...props.claims, ...props.scopelessClaims],
    props.group.claims
  );

  const [scopes, setScopes] = useState<FrontendScope[]>(parsedScopes);
  const [claims, setClaims] = useState<FrontendClaim[]>(parsedClaims);

  const [filteredScopes, setFilteredScopes] = useState<FrontendScope[]>(scopes);
  const [filteredClaims, setFilteredClaims] = useState<FrontendClaim[]>(claims);
  const [search, setSearch] = useState<string>('');

  const [group, setGroup] = useState<FrontendGroup>({
    id: props.group.id,
    name: props.group.name,
    scopeNames: props.group.scopes.map((scope) => scope.name),
    description: props.group.description,
  });
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  function updateGroupDetails(name: string, description?: string) {
    const body = {
      name,
      description,
    };

    fetch(`${props.routerPrefix}/admin/group/${props.group.name}/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedGroup: Group) => {
        setGroup({
          ...group,
          name: updatedGroup.name,
          description: updatedGroup.description,
        });
        setSuccess('Group details updated successfully');
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function addGroupScope(scope: FrontendScope) {
    fetch(`${props.routerPrefix}/admin/group/${group.name}/add/scope`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scopeName: scope.name }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedClaims: Claim[]) => {
        setScopes([...scopes]);
        setClaims(parseGroupClaims([...props.claims, ...props.scopelessClaims], updatedClaims));
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  function removeGroupScope(scope: FrontendScope) {
    fetch(`${props.routerPrefix}/admin/group/${group.name}/remove/scope`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scopeName: scope.name }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((updatedClaims: Claim[]) => {
        setScopes([...scopes]);
        setClaims(parseGroupClaims([...props.claims, ...props.scopelessClaims], updatedClaims));
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  function toggleScope(name: string) {
    const scope = scopes.find((s) => s.name === name);

    if (!scope) return;

    scope.enabled = !scope.enabled;

    if (scope.enabled) {
      addGroupScope(scope);
    } else {
      removeGroupScope(scope);
    }
  }

  function getEnabledScopesByClaim(claim: FrontendClaim): FrontendScope[] {
    return scopes.filter((scope) => scope.enabled && scope.claims.includes(claim.name));
  }

  function filterScopesAndClaims(filterTerm: string) {
    const filteredClaims = filterClaims(filterTerm, claims);
    const includedClaims = filteredClaims.map((c) => c.name.toLowerCase());
    const filteredScopes = filterScopes(filterTerm, scopes, includedClaims);
    setFilteredScopes(filteredScopes);
    setFilteredClaims(filteredClaims);
  }

  function renderTabContent(search: URLSearchParams) {
    const tab = search.get('tab');
    switch (tab) {
      case 'permissions':
        return (
          <EditGroupPermissions
            claims={filteredClaims}
            scopes={filteredScopes}
            getEnabledScopesByClaim={getEnabledScopesByClaim}
            setSearch={setSearch}
            toggleScope={toggleScope}
          />
        );
      default:
        return (
          <EditGroupDetails routerPrefix={props.routerPrefix} group={group} updateGroupDetails={updateGroupDetails} />
        );
    }
  }

  const debouncedFilter = useCallback(
    debounce((search: string) => {
      filterScopesAndClaims(search);
    }, 250),
    []
  );

  useEffect(() => {
    filterScopesAndClaims(search);
  }, [scopes, claims]);

  useEffect(() => {
    debouncedFilter(search);
  }, [search]);

  return (
    <>
      <SuccessNotification message={success} setMessage={setSuccess} autoHide />
      <ErrorNotification message={error} setMessage={setError} />
      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <WithTabs tabs={tabs} setTabs={setTabs}>
          {renderTabContent(new URLSearchParams(window.location.search))}
        </WithTabs>
      </WithDefaultNavBar>
    </>
  );
}
